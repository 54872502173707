import FBIcon from "src/Assets/Icons/Socials/FB.png";
import IGIcon from "src/Assets/Icons/Socials/IG.png";
import TiktokIcon from "src/Assets/Icons/Socials/Tiktok.png";
import GooglePlayDownloadIcon from "src/Assets/Icons/Socials/GooglePlay.png";
import AppStoreDownloadIcon from "src/Assets/Icons/Socials/AppleStore.png";

import {
  HELP_CENTER_LINK,
  PRIVACY_LINK,
  SHOPEE_APPSTORE_LINK,
  SHOPEE_GOOGLEPLAY_LINK,
  TOS_LINK,
} from "src/Constants";

export const SOCIAL_MEDIA_CONTENT = {
  label: "Follow us: ",
  content: [
    {
      icon: FBIcon,
      url: "https://www.facebook.com/ShopeePayPH/",
    },
    {
      icon: IGIcon,
      url: "https://www.instagram.com/shopeepay_ph/",
    },
    {
      icon: TiktokIcon,
      url: "https://www.tiktok.com/@shopeepay_ph",
    },
  ],
};

export const DOWNLOAD_CONTENT = {
  label: "Download the app now!",
  content: [
    {
      icon: AppStoreDownloadIcon,
      url: SHOPEE_APPSTORE_LINK,
    },
    {
      icon: GooglePlayDownloadIcon,
      url: SHOPEE_GOOGLEPLAY_LINK,
    },
  ],
};

export const SERVICE_CONTENT = {
  label: "Customer Service",
  content: [
    {
      label: "ShopeePay Features",
      url: "/feature",
    },
    {
      label: "Merchants",
      url: "/merchant",
    },
    {
      label: "Help Center",
      url: HELP_CENTER_LINK,
      props: {
        target: "_blank",
        rel: "noreferrer",
      },
    },
  ],
};

export const EXPLORE_CONTENT = {
  label: "Explore ShopeePay",
  content: [
    {
      label: "About Us",
      url: "/#about",
    },
    {
      label: "Terms of Service",
      url: TOS_LINK,
      props: {
        target: "_blank",
        rel: "noreferrer",
      },
    },
    {
      label: "Privacy Policy",
      url: PRIVACY_LINK,
      props: {
        target: "_blank",
        rel: "noreferrer",
      },
    },
  ],
};

export const COPYRIGHT_CONTENT = {
  label:
    "Copyright © Shopee Limited . Trademarks belong to their respective owners. All rights reserved. ",
  content: [
    {
      label: "Terms of Service",
      props: {
        href: TOS_LINK,
        target: "_blank",
        rel: "noreferrer",
      },
    },
    {
      label: "Privacy Policy",
      props: {
        href: PRIVACY_LINK,
        target: "_blank",
        rel: "noreferrer",
      },
    },
  ],
};
