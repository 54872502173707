export const MERCHANT_TOS_LINK =
  "https://help.shopee.ph/portal/article/77303-ShopeePay-Merchant-Terms-of-Service?previousPage=secondary%20category";

export const TOS_LINK = "https://help.shopee.ph/portal/article/133983";

export const PRIVACY_LINK =
  "https://help.shopee.ph/portal/article/77307?gclid=Cj0KCQjwxuCnBhDLARIsAB-cq1pM23WCSrVAx9Me_e3mvq5_kbatXUfaY0w-gIA0e_AcJ3odAIKTY8UaAk9FEALw_wcB";

export const HELP_CENTER_LINK =
  "https://help.shopee.ph/portal/category/38-Payments/825-ShopeePay?page=1";

export const SHOPEE_APPSTORE_LINK =
  "https://apps.apple.com/ph/app/shopee-ph-shop-on-3-3-3-15/id959841854";

export const SHOPEE_GOOGLEPLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.shopee.ph&hl=en&gl=US";

export const SHOPEEPAY_PRODUCT_LINK = "https://product.shopeepay.com";
