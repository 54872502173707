import { lazy } from "react";

const HomePage = lazy(() => import("src/Pages/HomePage"));
const NoMatchPage = lazy(() => import("src/Pages/NoMatch"));
const FeaturePage = lazy(() => import("src/Pages/FeaturePage"));
const MerchantPage = lazy(() => import("src/Pages/MerchantPage"));
const DownloadPage = lazy(() => import("src/Pages/DownloadPage"));
const ContactUsPage = lazy(() => import("src/Pages/ContactUsPage"));

const RegisterSuccessPage = lazy(() =>
  import("src/Pages/RegistrationSuccessPage")
);

export const NAVIGATION_ROUTES = Object.freeze({
  home: {
    path: "/",
    exact: true,
    element: <HomePage />,
  },
  feature: {
    path: "/feature",
    element: <FeaturePage />,
  },
  merchant: {
    path: "/merchant",
    element: <MerchantPage />,
  },
  RegisterSuccess: {
    path: "/register",
    search: "?success=true",
    element: <RegisterSuccessPage />,
  },
  noMatch: {
    path: "*",
    element: <NoMatchPage />,
  },
  Download: {
    path: "/download",
    element: <DownloadPage />,
  },
  ContactUs: {
    path: "/contact-us",
    element: <ContactUsPage />,
  },
});
