import { HELP_CENTER_LINK, SHOPEEPAY_PRODUCT_LINK } from "src/Constants";

export const MENU_LIST = [
  { title: "Home", path: "/" },
  { title: "Features", path: "/feature" },
  { title: "Merchant", path: "/merchant" },
  { title: "Help Center", path: HELP_CENTER_LINK },
  { title: "Product", path: SHOPEEPAY_PRODUCT_LINK },
];

export const EXTRA_BUTTON = {
  title: "Download",
  path: "/download",
};
