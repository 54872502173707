import { ShopeePayIcon } from "@sipp/tangerine";
import React from "react";
import QR from "src/Assets/Icons/ShopeeAppQR/QR.png";
import BrowseIcon from "src/Assets/Icons/Socials/Browse.png";
import ChatIcon from "src/Assets/Icons/Socials/Chat.png";
import CallIcon from "src/Assets/Icons/Socials/Call.png";
import EmailIcon from "src/Assets/Icons/Socials/Email.png";

import {
  COPYRIGHT_CONTENT,
  DOWNLOAD_CONTENT,
  EXPLORE_CONTENT,
  SERVICE_CONTENT,
  SOCIAL_MEDIA_CONTENT,
} from "../AppFooter/Constants";

import "./index.less";

const FollowUs = () => {
  const { label, content } = SOCIAL_MEDIA_CONTENT;

  return (
    <div className="footer-content__follow-us">
      <h1>{label}</h1>
      <div className="footer-content__follow-us-content">
        {content.map(({ icon, url }) => (
          <a
            key={url}
            href={url}
            className="qr-code"
            target="_blank"
            rel="noreferrer"
          >
            {<img src={icon} alt="Download Icon" width={40} />}
          </a>
        ))}
      </div>
    </div>
  );
};
const DownloadOnSosmed = () => {
  const { label, content } = DOWNLOAD_CONTENT;

  return (
    <div className="footer-content__download">
      <h1>{label}</h1>
      <div className="footer-content__download-content">
        {content.map(({ icon, url }) => (
          <a
            key={url}
            href={url}
            className="qr-code"
            target="_blank"
            rel="noreferrer"
          >
            {<img src={icon} alt="Download Icon" width={115} />}
          </a>
        ))}
      </div>

      <a href="https://shopee.ph/web" className="qr-code">
        <img src={QR} alt="PH QR code" width={65} />
      </a>
    </div>
  );
};

const ContactUs = () => {
  return (
    <div className="footer-content__follow-us">
      <h1>Got any questions?</h1>
      <p>We are available to assist you every day.</p>
      <div className="footer-content__contact-us-content">
        <li>
          <div className="icon">
            <img src={BrowseIcon} alt="Browser Icon" width={20} />
          </div>
          Browse our
          <a
            href="https://help.shopee.ph/portal/4/category/38-Payments/825-ShopeePay?page=1"
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </a>
        </li>
        <li>
          <div className="icon">
            <img src={ChatIcon} alt="Chat Icon" width={20} />
          </div>
          Chat or call via the Shopee app
        </li>
        <li>
          <div className="icon">
            <img src={CallIcon} alt="Call Icon" width={20} />
          </div>
          Call (02) 8880-5200
        </li>
        <li>
          <div className="icon">
            <img src={EmailIcon} alt="Email Icon" width={20} />
          </div>
          Email sppcustomerservice.ph@shopee.com or
          shopeepay_fraudsupport_ph@shopee.com
        </li>
        <br />
        <p>
          ShopeePay Philippines, Inc. is regulated by the Bangko Sentral ng
          Pilipinas (
          <a href="https://www.bsp.gov.ph/" target="_blank" rel="noreferrer">
            https://www.bsp.gov.ph/
          </a>
          ).
        </p>
      </div>
    </div>
  );
};

const OtherLinks = () => {
  return (
    <div className="footer-content__other-links">
      <div className="footer-content__other-links-content">
        <div className="footer-content__other-links-content-item">
          <p>{SERVICE_CONTENT.label}</p>
          {SERVICE_CONTENT.content.map((link) => {
            const openNewTab = link.url.includes("https:/");

            return (
              <li>
                <a
                  {...link?.props}
                  key={link.url}
                  href={link.url}
                  rel="noreferrer"
                  target={openNewTab ? "_blank" : "_self"}
                >
                  {link.label}
                </a>
              </li>
            );
          })}
        </div>

        <div className="footer-content__other-links-content-item">
          <p>{EXPLORE_CONTENT.label}</p>
          {EXPLORE_CONTENT.content.map((link) => {
            const openNewTab = link.url.includes("https:/");

            return (
              <li>
                <a
                  {...link?.props}
                  key={link.url}
                  href={link.url}
                  rel="noreferrer"
                  target={openNewTab ? "_blank" : "_self"}
                >
                  {link.label}
                </a>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Copyright = () => {
  const [TermOfService, PrivacyPolicy] = COPYRIGHT_CONTENT.content;

  return (
    <div className="footer-copyright">
      <p>
        {COPYRIGHT_CONTENT.label}
        <a {...TermOfService.props}>{TermOfService.label}</a> |{" "}
        <a {...PrivacyPolicy.props}>{PrivacyPolicy.label}</a>
      </p>
    </div>
  );
};
function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <section className="footer-content__left">
            <ShopeePayIcon />
            <FollowUs />
            <ContactUs />
          </section>
          <section className="footer-content__right">
            <OtherLinks />
            <DownloadOnSosmed />
          </section>
        </div>
        <Copyright />
      </footer>
    </>
  );
}

export default Footer;
