import { React } from "react";
import { EXTRA_BUTTON, MENU_LIST } from "./Constants";
import { Navigation } from "@sipp/tangerine";

const AppHeader = () => {
  return (
    <Navigation menuList={MENU_LIST} extraButton={EXTRA_BUTTON} />
  );
};

export default AppHeader;
